import React from 'react'
import '../CSS/newsPapers.css'
import { Link } from 'react-router-dom'
import { 
  FaAngleLeft
} from 'react-icons/fa';


const NewspaperAlt = () => {
  return (
    <div className='newsPaperContent alternative'>
      <section className="altNewsContent">
        <div className="npcPageContainerTop">
          <img src={require('../assets/imgs/NewspaperBG.png')} alt="" />
          <Link to='/newspapers'><button><h6><FaAngleLeft/>Back to All Categories</h6></button></Link>
        </div>
        <h2>ALTERNATIVE NEWSPAPERS</h2>
        <h6>Find the most interesting issues, hot topics, and breaking news. Read the latest daily news from the list of Alternative Newspapers around the world!</h6>
        <div className="npContainers">
          <a href="" className="npAltNewsContent">
            <h4>Alabama - Lagniappe, Mobile</h4>
            <p>Alabama, USA</p>
          </a>
          <a href="" className="npAltNewsContent">
            <h4>Alabama - Lagniappe, Mobile</h4>
            <p>Alabama, USA</p>
          </a>
          <a href="" className="npAltNewsContent">
            <h4>Alabama - Lagniappe, Mobile</h4>
            <p>Alabama, USA</p>
          </a>
          <a href="" className="npAltNewsContent">
            <h4>Alabama - Lagniappe, Mobile</h4>
            <p>Alabama, USA</p>
          </a>
          <a href="" className="npAltNewsContent">
            <h4>Alabama - Lagniappe, Mobile</h4>
            <p>Alabama, USA</p>
          </a>
          <a href="" className="npAltNewsContent">
            <h4>Alabama - Lagniappe, Mobile</h4>
            <p>Alabama, USA</p>
          </a>
          <a href="" className="npAltNewsContent">
            <h4>Alabama - Lagniappe, Mobile</h4>
            <p>Alabama, USA</p>
          </a>
          <a href="" className="npAltNewsContent">
            <h4>Alabama - Lagniappe, Mobile</h4>
            <p>Alabama, USA</p>
          </a>
          <a href="" className="npAltNewsContent">
            <h4>Alabama - Lagniappe, Mobile</h4>
            <p>Alabama, USA</p>
          </a>
          <a href="" className="npAltNewsContent">
            <h4>Alabama - Lagniappe, Mobile</h4>
            <p>Alabama, USA</p>
          </a>
          <a href="" className="npAltNewsContent">
            <h4>Alabama - Lagniappe, Mobile</h4>
            <p>Alabama, USA</p>
          </a>
          <a href="" className="npAltNewsContent">
            <h4>Alabama - Lagniappe, Mobile</h4>
            <p>Alabama, USA</p>
          </a>
        </div>
      </section>
    </div>
  )
}

export default NewspaperAlt