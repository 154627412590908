import React, { useEffect, useState } from 'react';
import '../CSS/newsChannels.css'
import { 
  FaPlayCircle
} from 'react-icons/fa';
import newschannel from '../../json_data/newschannel.json';
import NewsChannels_Headers from '../Headers/NewsChannels_Headers'

const NewsChannels = () => {
  const [newschannel_data] = useState(newschannel.channels);

  return (
    <>
    <NewsChannels_Headers />
    <div className='mainContainer newsChannels'>
        <section className="tduNewsChannelsPage top">
          <div className="nscPageContainerTop">
            <img src={require('../assets/imgs/LiveChannelsBG.png')} alt="" />
          </div>
          <h2>{newschannel.h_1}</h2>
          <h5>{newschannel.p_tag}</h5>
          <div className="nscPageContainerTop1">
              {newschannel_data.map((data) => (
                <a href={data.url} target='blank'>
                  <div className="newsChannelContainer">
                    <img src={require('../assets/imgs/Logos/LiveTelecast.png')} alt="" />
                    <h4><FaPlayCircle/></h4>
                  </div>
                  <span>
                    <h4>{data.name}</h4>
                    <h6>{data.country}</h6>
                  </span>
                </a>
                ))}
          </div>
        </section>
    </div>
    </>
  )
}

export default NewsChannels