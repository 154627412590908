import React from 'react'
import '../CSS/weatherForcast.css'

const WeatherForecast = () => {
  return (
    <div className='mainContainer weatherForecast'>
        
    </div>
  )
}

export default WeatherForecast