import React, { useEffect, useState } from 'react'
import "../CSS/home.css";
import { Link } from 'react-router-dom';
import 'react-awesome-slider/dist/styles.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { 
  FaCheckCircle,
  FaBroadcastTower,
  FaExpandArrowsAlt,
  FaGlobeAmericas,
  FaMap,
  FaCaretUp,
  FaCaretDown,
  FaAngleDoubleRight,
  FaRocket,
  FaMapMarked,
  FaMapMarkerAlt,
  FaStar,
  FaFacebookSquare,
  FaTwitter,
  FaLinkedin,
  FaPinterestP,
  FaYoutube,
  FaTiktok,
  FaTv,
  FaTimes,
  FaCaretRight,
  FaFootballBall,
  FaRegFutbol,
} from 'react-icons/fa';
import MapGL from './api/Maps'; 

import ExchangeRates from './api/ExchangeRates';


ChartJS.register(ArcElement, Tooltip, Legend );
const Home = () => {
  const [hasScrolled, setHasScrolled] = useState(false);

  

  

  return (
    <div className='mainContainer home'>
      <section className="landingPageTDU top">
        <div className="landingTDUTop">
          <div className="ltduTop left">
            <div className="mapChange">
              <button><h6><FaGlobeAmericas/></h6></button>
              <button><h6><FaMap/></h6></button>
              {/* <button><h6><FaExpandArrowsAlt/></h6></button> */}
            </div>
            <MapGL />
            
          </div>
          {/* <div className="ltduTop right">
            <h4><FaBroadcastTower/> BREAKING NEWS</h4>
            <div className="breakingNews">
              <div className="worldBreakingNews">
                <div className='wbNews'>
                  <img src={require('../assets/imgs/BreakingNewsBG.png')} alt="" />
                  <h6>BREAKING NEWS Title No. 1</h6>
                  <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Non, eos aut maxime voluptatem, explicabo doloribus impedit inventore natus veniam illo accusantium cupiditate. </p>
                </div>
                <div className='wbNews'>
                  <img src={require('../assets/imgs/BreakingNewsBG.png')} alt="" />
                  <h6>BREAKING NEWS Title No. 1</h6>
                  <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Non, eos aut maxime voluptatem, explicabo doloribus impedit inventore natus veniam illo accusantium cupiditate. </p>
                </div>
                <div className='wbNews'>
                  <img src={require('../assets/imgs/BreakingNewsBG.png')} alt="" />
                  <h6>BREAKING NEWS Title No. 1</h6>
                  <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Non, eos aut maxime voluptatem, explicabo doloribus impedit inventore natus veniam illo accusantium cupiditate. </p>
                </div>
              </div>
            </div>
            <Link to='/breaking-news' target='blank'><button><h6>View More <FaAngleDoubleRight className='faIcon'/></h6></button></Link>
          </div> */}
        </div>
      </section>
      <section className="landingPageTDU mid">
        <div className="landingTDUMid1">
          <h4>USD EXCHANGE RATES</h4>
          <ExchangeRates />
        </div>
        <div className="landingTDUMid2">
          <h4><FaBroadcastTower className='faIcons'/>BREAKING NEWS</h4>
          <div className="lndBreakNews container">
            <div className="lndBreakNewsContent left">
              <img src={require('../assets/imgs/BreakingNewsBG.jpg')} alt="" />
              <div className="lndbnLeftContent">
                <h5>Featured Breaking News Content Title</h5>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minima nihil ad velit quos incidunt? In, rem voluptatibus. Ipsa repudiandae blanditiis fuga adipisci deserunt sunt, repellendus commodi saepe velit labore. Sunt!</p>
              </div>
            </div>
            <div className="lndBreakNewsContent right">
              <div className="lndbnRightContent">
                <img src={require('../assets/imgs/BreakingNewsBG.jpg')} alt="" />
                <div><h6>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h6></div>
              </div>
              <div className="lndbnRightContent">
                <img src={require('../assets/imgs/BreakingNewsBG.jpg')} alt="" />
                <div><h6>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h6></div>
              </div>
              <div className="lndbnRightContent">
                <img src={require('../assets/imgs/BreakingNewsBG.jpg')} alt="" />
                <div><h6>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h6></div>
              </div>
              <div className="lndbnRightContent">
                <img src={require('../assets/imgs/BreakingNewsBG.jpg')} alt="" />
                <div><h6>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h6></div>
              </div>
              <div className="lndbnRightContent">
                <img src={require('../assets/imgs/BreakingNewsBG.jpg')} alt="" />
                <div><h6>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h6></div>
              </div>
              <div className="lndbnRightContent">
                <img src={require('../assets/imgs/BreakingNewsBG.jpg')} alt="" />
                <div><h6>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h6></div>
              </div>
            </div>
          </div>
          <div className="viewMoreButton">
            <Link to='/breaking-news'><button><h6>View More <FaAngleDoubleRight className='faIcons'/></h6></button></Link>
          </div>
        </div>
        <div className="landingTDUMid3">
          <h4><FaTv className='faIcons'/>NEWS CHANNELS</h4>
          <h5>Get the latest and trending news among the Live Television platforms.</h5>
          <div className="lndTrendingNews container">
            <div className="lndtnContent">
              <img src={require('../assets/imgs/TrendingNewsBG.jpg')} alt="" />
              <div><h6>Trending News Title No. 1 Lorem ipsum dolor sit amet,</h6></div>
            </div>
            <div className="lndtnContent">
              <img src={require('../assets/imgs/TrendingNewsBG.jpg')} alt="" />
              <div><h6>Trending News Title No. 1 Lorem ipsum dolor sit amet,</h6></div>
            </div>
            <div className="lndtnContent">
              <img src={require('../assets/imgs/TrendingNewsBG.jpg')} alt="" />
              <div><h6>Trending News Title No. 1 Lorem ipsum dolor sit amet,</h6></div>
            </div>
            <div className="lndtnContent">
              <img src={require('../assets/imgs/TrendingNewsBG.jpg')} alt="" />
              <div><h6>Trending News Title No. 1 Lorem ipsum dolor sit amet,</h6></div>
            </div>
          </div>
          <div className="viewMoreButton">
            <Link to='/newschannels'><button><h6>View More <FaAngleDoubleRight className='faIcons'/></h6></button></Link>
          </div>
        </div>
        <div className="landingTDUMid4">
          <h3>SPORTS</h3>
          <div className="lndSportsContent">
            <h4>INTER MIAMI <br /><span>FIRST TROPHY</span></h4>
            <h5>The Messi Magic</h5>
            <img src={require('../assets/imgs/Sports/MessiInterMiami.png')} alt="" />
          </div>
          <div className="viewMoreButton">
            <Link to='/sports'><button><h6>View More <FaAngleDoubleRight className='faIcons'/></h6></button></Link>
          </div>
        </div>
        <div className="landingTDUMid5">
          <h4><FaMapMarked className='faIcons'/>TRAVEL</h4>
          <h5>Check out the beauty of the world and find a place for you.</h5>
          <div className="lndTravelContainer">
            <img id='travelBG' src={require('../assets/imgs/CoronPalawan.jpeg')} alt="" />
            <div className="lndTravelContent">
              <div className="lndtContent left">
                <h4>CORON PALAWAN</h4>
                <h5><FaMapMarkerAlt className='faIcons'/>Philippines</h5>
              </div>
              <div className="lndtContent right">
                <p>
                  Coron is a fantastic base for adventure, especially for underwater explorers seeking to dive amid the Japanese shipwrecks of the 1940s. Hop a guided mangrove kayak tour or practice your killer spike on the beach-volleyball court at Atwayan Beach. Jungle treks, horseback tours and island camping will further quench your thirst for the ultimate outdoor experience.
                </p>
              </div>
            </div>
          </div>
          <div className="viewMoreButton">
            <Link to='/travels'><button><h6>View More <FaAngleDoubleRight className='faIcons'/></h6></button></Link>
          </div>
        </div>
        {/* <div className="landingTDUMid6">
          <h5>TDU Visitors Feedback</h5>
          <div className="lndTDUFeedback">
            <div className="avatarFeedback">
              <img src={require('../assets/imgs/Silhoutte/character01.png')} alt="" />
              <span>
                <h6>Jim Smith</h6>
                <h6><FaStar className='faIcons'/><FaStar className='faIcons'/><FaStar className='faIcons'/><FaStar className='faIcons'/><FaStar className='faIcons'/></h6>
                <p>"Impartial Reporting: Appreciate the unbiased approach in delivering news. It's become my go-to for reliable information."</p>
              </span>
            </div>
            <div className="avatarFeedback">
              <img src={require('../assets/imgs/Silhoutte/character02.png')} alt="" />
              <span>
                <h6>Michael Johnson</h6>
                <h6><FaStar className='faIcons'/><FaStar className='faIcons'/><FaStar className='faIcons'/><FaStar className='faIcons'/><FaStar className='faIcons'/></h6>
                <p>"Clear and Concise: Love how the news is presented in a straightforward manner. No sensationalism, just the facts."</p>
              </span>
            </div>
            <div className="avatarFeedback">
              <img src={require('../assets/imgs/Silhoutte/character03.png')} alt="" />
              <span>
                <h6>Samantha Cruz</h6>
                <h6><FaStar className='faIcons'/><FaStar className='faIcons'/><FaStar className='faIcons'/><FaStar className='faIcons'/><FaStar className='faIcons'/></h6>
                <p>"Timely Updates: This platform keeps me in the loop with current events. Quick, accurate, and well-written articles!"</p>
              </span>
            </div>
            <div className="avatarFeedback">
              <img src={require('../assets/imgs/Silhoutte/character04.png')} alt="" />
              <span>
                <h6>George Ling</h6>
                <h6><FaStar className='faIcons'/><FaStar className='faIcons'/><FaStar className='faIcons'/><FaStar className='faIcons'/><FaStar className='faIcons'/></h6>
                <p>"Diverse Coverage: I appreciate the wide range of topics covered, from global affairs to local issues. It provides a well-rounded view of what's happening."</p>
              </span>
            </div>
          </div>
        </div> */}
        <div className="landingTDUMid7">
          <div className="lndContactForm">
            <div className="lndcfContent left">
              <h5>WE VALUE YOUR INSIGHTS, <br />CONTACT US.</h5>
              <p>Let us deliver both correct and true experience.</p>
              <span>
                <a href=""><h6><FaFacebookSquare/></h6></a>
                <a href=""><h6><FaTwitter/></h6></a>
                <a href=""><h6><FaLinkedin/></h6></a>
                <a href=""><h6><FaPinterestP/></h6></a>
                <a href=""><h6><FaYoutube/></h6></a>
                <a href=""><h6><FaTiktok/></h6></a>
              </span>
            </div>
            <hr />
            <div className="lndcfContent right">
              <h5>SEND US INSIGHTS HERE</h5>
              <div className="tduContactForm">
                <input type="text" placeholder='Full Name'/>
                <input type="emain" placeholder='Email'/>
                <textarea name="" id="" cols="10" rows="10" placeholder='Type Here...'></textarea>
                <button><h6>Send</h6></button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home;