import React, { useEffect, useState, useMemo, useRef } from 'react'
import '../CSS/newsPapers.css'
import { Link } from 'react-router-dom'
import { 
  FaAngleLeft
} from 'react-icons/fa';
import NewspapersWorld_Header from '../Headers/NewspapersWorld_Header'
import worldnewspaper from '../../json_data/worldnewspaper.json';

const NewspapersWorld = () => {

  const [world_newspapers_value] = useState(worldnewspaper.world_newspapers);

  return (
    <>
    <NewspapersWorld_Header />
    <div className='newsPaperContent world'>
      <section className="altNewsContent">
        <div className="npcPageContainerTop">
          <img src={require('../assets/imgs/NewspaperBG.png')} alt="" />
          <Link to='/newspapers'><button><h6><FaAngleLeft/>Back to All Categories</h6></button></Link>
        </div>
        <h2>WORLD NEWSPAPERS</h2>
        <h6>Check the list of real-time news from World Newspapers Online. We cover news on politics, society, culture, sport, travel, and more. Learn more.</h6>
        <div className="npContainers">
            {world_newspapers_value.map((data) => (
              <a href={data.link} target="_blank" className="npCollegeNewsContent">
                  <h4>{data.newspaper_name}</h4>
                  <p>{data.p_tag}</p>
              </a>
            ))}
        </div>
      </section>
    </div>
    </>
  )
}

export default NewspapersWorld