import React, { useEffect, useState, useMemo, useRef } from 'react'
import '../CSS/newsPapers.css'
import { Link } from 'react-router-dom'
import { 
  FaAngleLeft
} from 'react-icons/fa';
import NewspapersCollege_Headers from '../Headers/NewspapersCollege_Headers'
import collegenewspapersdata from '../../json_data/collegenewspapersdata.json';

const NewspapersCollege = () => {

const [collegenewspapers_data] = useState(collegenewspapersdata.college_newspapers);
console.log(collegenewspapers_data)

const [selected, setSelected] = useState(null)

const toggle = (i) => {
  if(selected == i){
    return setSelected(null)
  }
  setSelected(i)
}

  return (
    <>
    <NewspapersCollege_Headers />
    <div className='newsPaperContent college'>
      <section className="altNewsContent">
        <div className="npcPageContainerTop">
          <img src={require('../assets/imgs/NewspaperBG.png')} alt="" />
          <Link to='/newspapers'><button><h6><FaAngleLeft/>Back to All Categories</h6></button></Link>
        </div>
        <h2>COLLEGE NEWSPAPERS</h2>
        <h6>Browse through the list of student newspapers (College Newspapers and University Newspapers) from the best colleges and universities around the world.</h6>
        <br/><br/><br/><br/>
          {collegenewspapers_data.map((data, i) => (
            <div className="item">
              <div className="title" onClick={() => toggle(i)}>
                <h5>{data.country_college}</h5>
                <span>{selected === i ? '-' : '+'}</span>
              </div>
              <div className={selected === i ? 'npContainers content show' : 'content'}>
                {data.list_of_schools.map((data_list_of_schools) => (
                  <a href={data_list_of_schools.url} className="npAltNewsContent">
                    <h4>{data_list_of_schools.name}</h4>
                  </a>
                ))}
              </div>
            </div>
            ))}
      </section>
    </div>
  </>
  )
}

export default NewspapersCollege