import React, { useEffect, useState } from 'react';
import '../CSS/breakingNews.css'
import { Link } from 'react-router-dom';
import 'react-awesome-slider/dist/styles.css';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { 
  FaBroadcastTower,
  FaGlobeAmericas,
  FaCaretUp,
  FaCaretDown,
  FaAngleDoubleRight,
  FaStar,
  FaFacebookSquare,
  FaTwitter,
  FaLinkedin,
  FaPinterestP,
  FaYoutube,
  FaTiktok,
  FaTv,
  FaTimes,
  FaCaretRight,
  FaFire,
} from 'react-icons/fa';
import axios from 'axios';
import cheerio  from 'cheerio';
import BreakingNews_Headers from '../Headers/BreakingNews_Headers'
import breakingnewsdata from '../../json_data/breakingnewsdata.json';


const BreakingNews = () => {
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const [breakingnews_tag] = useState(breakingnewsdata.items);

  console.log(breakingnews_tag)

  return (
    <>
    <BreakingNews_Headers />
    <div className='mainContainer breakingNews'>
        <section className="tduBreakingNewsPage top">
          <div className="brnPageContainerTop">
            <div className="brnSliderContainer">
              <div className="sliderContainer">
                <div className="sliderFeat">
                  <AutoplaySlider className="homeSlider"
                      play={true}
                      cancelOnInteraction={false} // should stop playing on user interaction
                      interval={3000}
                  >
                    <div className="featContent">
                      <img src={process.env.PUBLIC_URL + "./carousel/BreakingNewsBG04.png"} alt="Insert Banner" />
                    </div>
                    <div className="featContent">
                      <img src={process.env.PUBLIC_URL + "./carousel/BreakingNewsBG04.png"} alt="Insert Banner" />
                    </div>
                    <div className="featContent">
                      <img src={process.env.PUBLIC_URL + "./carousel/BreakingNewsBG04.png"} alt="Insert Banner" />
                    </div>
                    <div className="featContent">
                      <img src={process.env.PUBLIC_URL + "./carousel/BreakingNewsBG04.png"} alt="Insert Banner" />
                    </div>
                  </AutoplaySlider>
                </div>
              </div>
            </div>
          </div>

          <div className="brnPageContainerBottom">

          {breakingnews_tag.map((data) => (
            <>
              <div className="brnpAnchorLinks">
                <a href={data.link} target="_blank"><h4>{data.h2_tag}</h4></a>
                <p>{data.description}</p>
                <span>
                    {data.p_tag.map((data_ptag) => (
                      <a href={data_ptag.link} target="_blank"><h6>{data_ptag.news}</h6></a>
                    ))}
                  </span>
                </div>
              </>
            ))}
          </div>
        </section>
    </div>
    </>
  )
}

export default BreakingNews