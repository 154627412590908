import React, { useEffect, useState } from "react";
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';

export default function ExchangeRates() {
  const [exchangeRates, setExchangeRates] = useState(null);
  const [error, setError] = useState(null);
  const [randomCurrencies, setRandomCurrencies] = useState([]);

  async function fetchData() {
    const exchangeUrl = 'https://v6.exchangerate-api.com/v6/e888bcd259d4791249e27343/latest/USD';
    
    try {
      const response = await fetch(exchangeUrl);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setExchangeRates(data);

      // Get all currency codes from the API response
      const allCurrencies = Object.keys(data.conversion_rates);

      // Shuffle the currency codes randomly
      const shuffledCurrencies = shuffleArray(allCurrencies);

      // Select the first 9 currencies from the shuffled list
      const selectedCurrencies = shuffledCurrencies.slice(0, 9);

      setRandomCurrencies(selectedCurrencies);
    } catch (error) {
      setError(error.message);
      console.error("Error fetching exchange rates:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }

  return (
    <div className="dollarExchange">
      {error ? (
        <p>Error fetching exchange rates: {error}</p>
      ) : (
        exchangeRates ? (
          <div className="exchangeDetails">
            {randomCurrencies.map((currency, index) => (
              <span key={index} className="exr">
                <h5>
                  {exchangeRates.conversion_rates[currency] > 1 ? (
                    <FaCaretUp className='faIcon curUp' />
                  ) : (
                    <FaCaretDown className='faIcon curDown' />
                  )}{' '}
                  {exchangeRates.conversion_rates[currency].toFixed(2)}
                </h5>
                <p>USD/{currency}</p>
              </span>
            ))}
          </div>
        ) : null
      )}
    </div>
  );
}
