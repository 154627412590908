import React, { useState, useEffect, useRef } from 'react'
import "./CSS/nav.css";
import { 
    FaBars, 
    FaTimes,
    FaFacebookSquare,
    FaTwitter,
    FaLinkedin,
    FaPinterestP,
    FaYoutube,
    FaTiktok,
    FaGlobeAmericas,
    FaBroadcastTower,
    FaTv,
    FaNewspaper,
    FaCloudSunRain,
    FaFootballBall,
    FaGlassCheers,
    FaMapMarked,
    FaPlane,
    FaMapMarkerAlt,
    FaGlobe
} from 'react-icons/fa';

import { Link } from 'react-router-dom';

const Nav = () => {

  const [viewSecondNav, setViewSecondNav] = useState(false)  
  const navRef = useRef(null);

  const handleViewSecondNav = () => {
    setViewSecondNav(true);
  }
  const handleCloseViewSecondNav = () => {
    setViewSecondNav(false);
  }

  

  const [viewLogin, setViewLogin] = useState(false)
  const [viewRegister, setViewRegister] = useState(false)
  const handleViewLogin = () => {
    setViewLogin(true)
    setViewRegister(false)
  }
  const handleViewRegister = () => {
    setViewRegister(true)
    setViewLogin(false)
  }
  const handleCloseLoginRegister = () => {
    setViewLogin(false)
    setViewRegister(false)
  }

  if(viewLogin == true ||
    viewRegister == true  
  ){
    window.document.body.style.overflow = 'hidden';
  } else{
    window.document.body.style.overflow = 'auto';
  }

  


  return (
    <nav>
      {viewLogin &&<div className="loginContainer">
        <div className="loginContent">
          <button onClick={handleCloseLoginRegister} id='closeLogin'><h6><FaTimes/></h6></button>
          <img src={require('./assets/imgs/TheDailyUniverseLogo00.png')} alt="" />
          <h4>LOGIN</h4>
          <form action="http://localhost/TheDailyUniverse/tduLogin.php" method='POST'>
            <input type="username" name='username' placeholder='Username'/>
            <input type="password" name='password' placeholder='Password'/>
            <button type='submit' id='login' name='loginUser'><h6>LOGIN</h6></button>
          </form>
          <a href=""><p>Forgot Password?</p></a>
          <button onClick={handleViewRegister} id='register'><p>Register an Account</p></button>
        </div>
      </div>}
      {viewRegister &&<div className="registerContainer">
        <div className="registerContent">
          <button onClick={handleCloseLoginRegister} id='closeLogin'><h6><FaTimes/></h6></button>
          <img src={require('./assets/imgs/TheDailyUniverseLogo00.png')} alt="" />
          <h4>SIGN UP</h4>
          <form action="http://localhost/TheDailyUniverse/tduLogin.php" method='POST'>
            <input type="username" name='username' placeholder='Username'/>
            <input type="email" name='email' placeholder='Email'/>
            <input type="password" name='password' placeholder='Password'/>
            {/* <input type="password" placeholder='Confirm Password'/> */}
            <button type='submit' id='login' name='createAccount'><h6>REGISTER</h6></button>
          </form>
          <button onClick={handleViewLogin} id='register'><p>Login to Account</p></button>
        </div>
      </div>}
      <div className="mainNavContainer top">
        <div className="navContainer main">
            <div className="navContent left">
                <Link to="/">
                    <img src={require('./assets/imgs/TheDailyUniverseLogo00.png')} alt="" />
                </Link>
            </div>
            <div className="navContent socials">
              <a href=""><h6><FaFacebookSquare/></h6></a>
              <a href=""><h6><FaTwitter/></h6></a>
              <a href=""><h6><FaLinkedin/></h6></a>
              <a href=""><h6><FaPinterestP/></h6></a>
              <a href=""><h6><FaYoutube/></h6></a>
              <a href=""><h6><FaTiktok/></h6></a>
            </div>
            <div className="navContent right">
              <Link><h6>America</h6></Link>
              <Link><h6>Europe</h6></Link>
              <Link><h6>Asia</h6></Link>
              <Link><h6>Africa</h6></Link>
              <Link><h6>Ocenia</h6></Link>
              <Link><h6>Antartica</h6></Link>
              <button onClick={handleViewLogin} id='loginButton'><h6>Login</h6></button>
              <button onClick={handleViewRegister} id='registerButton'><h6>Register</h6></button>
              {!viewSecondNav ?
              <button onClick={handleViewSecondNav}><h5><FaBars/></h5></button>:
              <button onClick={handleCloseViewSecondNav}><h5><FaTimes/></h5></button>}
            </div>
        </div>
      </div>
      {viewSecondNav &&(
      <div className="mainContainer sideNav">
        <Link to='/' onClick={handleCloseViewSecondNav}><h6><FaGlobeAmericas className='faIcons'/>World Map</h6></Link>
        <Link to='/breaking-news' onClick={handleCloseViewSecondNav}><h6><FaBroadcastTower className='faIcons'/>Breaking News</h6></Link>
        <Link to='/newschannels' onClick={handleCloseViewSecondNav}><h6><FaTv className='faIcons'/>News Channels</h6></Link>
        <Link to='/newspapers' onClick={handleCloseViewSecondNav}><h6><FaNewspaper className='faIcons'/>Newspaper</h6></Link>
        <Link to='/weatherforecast' onClick={handleCloseViewSecondNav}><h6><FaCloudSunRain className='faIcons'/>Weather Forecast</h6></Link>
        <Link to='/sports' onClick={handleCloseViewSecondNav}><h6><FaFootballBall className='faIcons'/>Sports</h6></Link>
        <Link to='/magazines' onClick={handleCloseViewSecondNav}><h6><FaNewspaper className='faIcons'/>Magazines</h6></Link>
        <Link to='restaurants' onClick={handleCloseViewSecondNav}><h6><FaGlassCheers className='faIcons'/>Restaurants</h6></Link>
        <Link to='/travel' onClick={handleCloseViewSecondNav}><h6><FaMapMarked className='faIcons'/>Travel</h6></Link>
        <Link to='/visaguide' onClick={handleCloseViewSecondNav}><h6><FaMapMarkerAlt className='faIcons'/>Visa Guide</h6></Link>
        <Link to='/airlines' onClick={handleCloseViewSecondNav}><h6><FaPlane className='faIcons'/>Airlines</h6></Link>
        <Link to='/airports' onClick={handleCloseViewSecondNav}><h6><FaPlane className='faIcons'/>Airports</h6></Link>
        <Link to='/globalinfo' onClick={handleCloseViewSecondNav}><h6><FaGlobe className='faIcons'/>Global Info</h6></Link>
      </div>)}
    </nav>
  ); 
}

export default Nav;