import React from 'react'
import '../CSS/newsPapers.css'
import { Link } from 'react-router-dom'
import { 
  FaAngleLeft,
  FaSearch,
  FaNewspaper
} from 'react-icons/fa';

const NewspapersLocal = () => {
  return (
    <div className='newsPaperContent local'>
      <section className="altNewsContent">
        <div className="npcPageContainerTop">
          <img src={require('../assets/imgs/NewspaperBG.png')} alt="" />
          <Link to='/newspapers'><button><h6><FaAngleLeft/>Back to All Categories</h6></button></Link>
        </div>
        <h2>LOCAL COUNTRIES NEWSPAPERS</h2>
        <h6>Get the latest news today! Find thousands of online newspapers from around the world. All newspapers are carefully sorted by region, country, and state.</h6>
        <div className="searchLocalNews">
            <input type="text" placeholder='Search Country'/>
            <button><h6><FaSearch/></h6></button>
        </div>
        <div className="npContainers">
            <ul>
                <h4>NORTH AMERICA</h4>
                <li><Link to='/newspapers/newspapers-by-country/country'><h6><FaNewspaper className='faIcons'/>Alabama</h6></Link></li>
                <li><Link to='/newspapers/newspapers-by-country/country'><h6><FaNewspaper className='faIcons'/>Alaska</h6></Link></li>
                <li><Link to='/newspapers/newspapers-by-country/country'><h6><FaNewspaper className='faIcons'/>Arizona</h6></Link></li>
                <li><Link to='/newspapers/newspapers-by-country/country'><h6><FaNewspaper className='faIcons'/>Arkansas</h6></Link></li>
                <li><Link to='/newspapers/newspapers-by-country/country'><h6><FaNewspaper className='faIcons'/>Alabama</h6></Link></li>
                <li><Link to='/newspapers/newspapers-by-country/country'><h6><FaNewspaper className='faIcons'/>Alaska</h6></Link></li>
                <li><Link to='/newspapers/newspapers-by-country/country'><h6><FaNewspaper className='faIcons'/>Arizona</h6></Link></li>
                <li><Link to='/newspapers/newspapers-by-country/country'><h6><FaNewspaper className='faIcons'/>Arkansas</h6></Link></li>
            </ul>
        </div>
      </section>
    </div>
  )
}

export default NewspapersLocal