import React from "react";
import {Helmet} from "react-helmet";

import breakingnewsdata from '../../json_data/breakingnewsdata.json';

const BreakingNews_Headers = () => {
    const DESCRIPTION = breakingnewsdata.meta_description 
    const KEY_WORDS = breakingnewsdata.meta_keywords
    const LINK_CANONICAL = breakingnewsdata.link_canonical

  return (
        
    <Helmet 
        onChangeClientState={(newState) => {
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
                metaDescription.setAttribute('content', DESCRIPTION || '');
            }
            const keywords = document.querySelector('meta[name="keywords"]');
            if (keywords) {
                keywords.setAttribute('content', KEY_WORDS || '');
            }
            const canonical = document.querySelector('link[rel="canonical"]');
            if (canonical) {
                canonical.setAttribute('href', LINK_CANONICAL || '');
            }
        }}>
        <title>{ breakingnewsdata.title_tag }</title>
    </Helmet>
  )
}

export default BreakingNews_Headers