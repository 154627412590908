import React from 'react'
import '../CSS/newsPapers.css'
import { Link } from 'react-router-dom'
import { 
  FaAngleLeft,
  FaSearch,
  FaNewspaper
} from 'react-icons/fa';

const NewspapersByCountry = () => {
  return (
    <div className='newsPaperContent local'>
      <section className="altNewsContent">
      <div className="npcPageContainerTop">
          <img src={require('../assets/imgs/NewspaperBG.png')} alt="" />
          <Link to='/newspapers/newspapers-by-country'><button><h6><FaAngleLeft/>Back to All Countries</h6></button></Link>
        </div>
        <h2>NEWSPAPERS in ALABAMA</h2>
        <div className="npContainers">
            <a href="" className="npCuntryNewsContent">
                <h4>Boston College - The Heights</h4>
                <p>United States</p>
            </a>
        </div>
      </section>
    </div>
  )
}

export default NewspapersByCountry