import React from 'react'
import '../CSS/globalInfo.css'

const GlobalInfo = () => {
  return (
    <div className='mainContainer globalInfo'>
        
    </div>
  )
}

export default GlobalInfo