import React from "react";
import {Helmet} from "react-helmet";
import worldnewspaper from '../../json_data/worldnewspaper.json';

const NewspapersWorld_Header = () => {
    const DESCRIPTION = worldnewspaper.meta_description 
    const KEY_WORDS = worldnewspaper.meta_keywords
    const LINK_CANONICAL = worldnewspaper.link_canonical
    const TITLE = worldnewspaper.title_tag

  return (
        
    <Helmet 
        onChangeClientState={(newState) => {
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
                metaDescription.setAttribute('content', DESCRIPTION || '');
            }
            const keywords = document.querySelector('meta[name="keywords"]');
            if (keywords) {
                keywords.setAttribute('content', KEY_WORDS || '');
            }
            const canonical = document.querySelector('link[rel="canonical"]');
            if (canonical) {
                canonical.setAttribute('href', LINK_CANONICAL || '');
            }
        }}>
        <title>{ TITLE }</title>
       
    </Helmet>
  )
}

export default NewspapersWorld_Header