import React from 'react'
import '../CSS/travelGuide.css'

const TravelGuide = () => {
  return (
    <div className='mainContainer travelGuide'>
        
    </div>
  )
}

export default TravelGuide