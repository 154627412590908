import React from 'react'
import '../CSS/restaurants.css'

const Restaurants = () => {
  return (
    <div className='mainContainer restaurants'>
        
    </div>
  )
}

export default Restaurants