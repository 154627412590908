import React from "react";
import {Helmet} from "react-helmet";

import newschannel from '../../json_data/newschannel.json';

const NewsChannels_Headers = () => {
    const DESCRIPTION = newschannel.description 
    const KEY_WORDS = newschannel.keywords
    const LINK_CANONICAL = newschannel.canonical
    const TITLE = newschannel.title

  return (
        
    <Helmet 
        onChangeClientState={(newState) => {
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
                metaDescription.setAttribute('content', DESCRIPTION || '');
            }
            const keywords = document.querySelector('meta[name="keywords"]');
            if (keywords) {
                keywords.setAttribute('content', KEY_WORDS || '');
            }
            const canonical = document.querySelector('link[rel="canonical"]');
            if (canonical) {
                canonical.setAttribute('href', LINK_CANONICAL || '');
            }
        }}>
        <title>{ TITLE }</title>
    </Helmet>
  )
}

export default NewsChannels_Headers