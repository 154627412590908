import React from "react";
import './CSS/nav.css'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="box">
          <img src={require('./assets/imgs/TheDailyUniverseLogo00.png')} alt="logo" className="footer-logo" />
          <p className="footer-des">Best Online World Newspapers, Tourist Destinations, Magazines and Tourist Visa Guide </p>
        </div>
        <div className="box">
            <p className="footer-des">
                Thousands of online newspapers, best tourist spots, popular magazines and tourist visa information from around the world.
            </p>
            <p>Subscribe for more.</p>
            <form>
                <input type="email" name="email" id="email" placeholder="Your Email Address"/>
                <input type="submit" value="Subscribe" />
            </form>
        </div>
      </div>
      <hr></hr>
      <div className="footer-bottom">
        <div className="copyright">
            COPYRIGHT © 2021 YOUNG TYCOONS INC, ALL RIGHTS RESERVED
        </div>
        <div>
            <p className="website-name"><a href='https://thedailyuniverse.com/'>thedailyuniverse.com</a> - World News Travel</p>
            <div className="terms">
                <a href="#0">Privacy Policy</a>
                <div className="divider"></div>
                <a href="#0">Terms of Use</a>
            </div>
        </div>
      </div>
    </footer>
  );
}
