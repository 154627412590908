import React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';


import Nav from './Components/Nav'
import Footer from './Components/Footer';
import ScrollToTop from './Components/Pages/ScrollToTop';

import Home from './Components/Pages/Home';
import Airlines from './Components/Pages/Airlines';
import BreakingNews from './Components/Pages/BreakingNews';
import GlobalInfo from './Components/Pages/GlobalInfo';
import Magazines from './Components/Pages/Magazines';

import NewsChannels from './Components/Pages/NewsChannels';
import NewspaperAlt from './Components/Pages/NewspapersAlt';
import NewspapersCollege from './Components/Pages/NewspapersCollege';
import NewspapersLocal from './Components/Pages/NewspapersLocal';
import NewspapersWorld from './Components/Pages/NewspapersWorld';

import NewspapersByCountry from './Components/Pages/NewspapersByCountry';

import NewsPapers from './Components/Pages/NewsPapers';
import Restaurants from './Components/Pages/Restaurants';
import Sports from './Components/Pages/Sports';
import TravelGuide from './Components/Pages/TravelGuide';
import VisaGuide from './Components/Pages/VisaGuide';
import WeatherForecast from './Components/Pages/WeatherForcast';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
function App() {
  
  return (
    <Router>
    <div>
      <ScrollToTop />
      <Nav />
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/airlines" element={<Airlines/>}/>
        <Route path="/breaking-news" element={<BreakingNews/>}/>
        <Route path="/globalinfo" element={<GlobalInfo/>}/>
        <Route path="/magazines" element={<Magazines/>}/>
        <Route path="/newschannels" element={<NewsChannels/>}/>

        <Route path="/newspapers" element={<NewsPapers/>}/>
        <Route path="/newspapers/alternative-newspapers" element={<NewspaperAlt/>}/>
        <Route path="/newspapers/college-newspapers" element={<NewspapersCollege/>}/>
        <Route path="/newspapers/newspapers-by-country" element={<NewspapersLocal/>}/>
        <Route path="/newspapers/world-newspapers" element={<NewspapersWorld/>}/>
        <Route path="/newspapers/newspapers-by-country/country" element={<NewspapersByCountry/>}/>
        
        <Route path="/restaurants" element={<Restaurants/>}/>
        <Route path="/sports" element={<Sports/>}/>
        <Route path="/travel" element={<TravelGuide/>}/>
        <Route path="/visaguide" element={<VisaGuide/>}/>
        <Route path="/weatherforecast" element={<WeatherForecast/>}/>

      </Routes>
      <Footer />
    </div>
    </Router>

    
  );
}


export default App;
