import React from 'react'
import '../CSS/newsPapers.css'
import { useState } from 'react'
import { 
  FaSearch
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import NewsPapers_Headers from '../Headers/NewsPapers_Headers'

const NewsPapers = () => {

  return (
    <>
    <NewsPapers_Headers />
    <div className='mainContainer newspapers'>
        <section className="tduNewspaperPage top">
          <div className="npcPageContainerTop">
            <img src={require('../assets/imgs/NewspaperBG.png')} alt="" />
          </div>
          <h2>WORLD'S NEWSPAPERS</h2>
          <h5>Stay Informed in traditional reading like before, checkout everyday's edition here.</h5>

          <div className="npContainers allNews">
            <Link to='/newspapers/alternative-newspapers'>
              <div className="npalContent">
                <img src={require('../assets/imgs/Newspaper/00.AltNewspaper.png')} alt="" />
                <p>Find the most interesting issues, hot topics, and breaking news. Read the latest daily news from the list of Alternative Newspapers around the world!</p>
              </div>
            </Link>
            <Link to='/newspapers/college-newspapers'>
              <div className="npalContent">
                <img src={require('../assets/imgs/Newspaper/00.CollegeNewspaper.png')} alt="" />
                <p>Browse through the list of student newspapers (College Newspapers and University Newspapers) from the best colleges and universities around the world.</p>
              </div>
            </Link>
            <Link to='/newspapers/newspapers-by-country'>
              <div className="npalContent">
                <img src={require('../assets/imgs/Newspaper/00.LocalNewspaper.png')} alt="" />
                <p>Get the latest news today! Find thousands of online newspapers from around the world. All newspapers are carefully sorted by region, country, and state.</p>
              </div>
            </Link>
            <Link to='/newspapers/world-newspapers'>
              <div className="npalContent">
                <img src={require('../assets/imgs/Newspaper/00.WorldNewspaper.png')} alt="" />
                <p>Check the list of real-time news from World Newspapers Online. We cover news on politics, society, culture, sport, travel, and more. Learn more.</p>
              </div>
            </Link>
          </div>
        </section>
    </div>
    </>
  )
}

export default NewsPapers