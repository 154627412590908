import React, { useState } from 'react';
import ReactMapGL from 'react-map-gl';
import './api.css';

// fixed error api

export default function MapGL() {
  const [viewport, setViewport] = useState({
    latitude: 0, 
    longitude: 0, 
    zoom: 1,
    width: '100%',
    height: '100vh',
  });

  const handleZoomChange = (e) => {
    // Ensure the zoom level stays within a specified range
    const minZoom = 1;
    const maxZoom = 20;
    const zoomIncrement = 0.5;

    setViewport((prevViewport) => {
      const newZoom = e.deltaY > 0 ? prevViewport.zoom - zoomIncrement : prevViewport.zoom + zoomIncrement;
      const clampedZoom = Math.min(Math.max(newZoom, minZoom), maxZoom);

      return {
        ...prevViewport,
        zoom: clampedZoom,
      };
    });
  };

  return (
    <div style={{ width: '100%', height: '100%' }} onWheel={handleZoomChange}>
      <ReactMapGL
        {...viewport}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} 
        mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
        onViewportChange={(newViewport) => setViewport(newViewport)}
        dragPan={true}
        attributionControl={false} // Hide Mapbox attribution
      >
      </ReactMapGL>
    </div>
  );
}
